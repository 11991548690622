.root {
  width: 100%;
  min-height: 100vh;
}

$buttonColor: #fd5c0d;

.resetButton {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: white;
  background: $buttonColor;
  border: 1px solid black;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.4);
  transition: background-color 100ms ease-in-out;
  user-select: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background: lighten($buttonColor, 5%);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.4);
  }

  &:active {
    background: darken($buttonColor, 5%);
  }

  position: fixed;
  right: 24px;
  bottom: 24px;

  @media print {
    display: none;
  }
}
