@import "colors";

.header {
  h2, h3 {
    margin: 0;
    color: $titleTextColor;
    text-transform: uppercase;

    > span {
      color: black;
      display: block;
      text-transform: none;
    }
  }

  h3 {
    padding: 6px 8px;
  }

  h2 {
    padding: 12px 8px;
  }

  margin-bottom: 32px;
}

.table {
  color: black;
  width: 100%;
  margin-bottom: 104px;
  border-collapse: collapse;

  thead > tr > th {
    text-align: left;
    border-bottom: 1px solid black;
    color: $titleTextColor;
    padding-left: 8px;
  }

  tfoot {
    background-color: rgba(0, 0, 0, 0.1);
    tr > td {
      padding: 4px 8px;
      text-align: center;
    }
  }
}

.missing {
  color: white;
  background: $missingColor;
}

.invalid {
  color: white;
  background: $invalidColor;
}

.mismatch {
  background: $mismatchColor;
}

.low {
  background: $lowValueColor;
}
