html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  width: 100%;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
