.root {
  h2 {
    margin: 0;
    padding: 8px 12px;
  }
}

.list {
  margin: 0;
  padding-left: 0;

  li {
    padding: 8px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
