@import "colors";

.missing {
  color: white;
  background: $missingColor;
}

.invalid {
  color: white;
  background: $invalidColor;
}

.row {
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  th {
    color: black;
    text-align: left;
  }

  > td, th {
    padding: 4px 8px;
  }
}

.mismatch {
  background: $mismatchColor;
}

.low {
  background: $lowValueColor;
}
